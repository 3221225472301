import React, { useState, useMemo } from "react";

import "./index.scss";
import "../../scss/main.scss";

//routing
import { Link } from "react-router-dom";

import Header from "../../components/Header";
import Counter from "../../components/Counter";
import DynamicText from "../../components/DynamicText";
import Footer from "../../components/Footer";

import { loadPhotosJson } from "../../utilities";

const AboutMe = (props) => {
	const [jsonPhoto, setJsonPhoto] = useState([]);

	useMemo(() => {
		window.scrollTo(0, 0);

		const jsonPhotoOrigin = loadPhotosJson();
		setJsonPhoto(jsonPhotoOrigin);

		//imposto il nome della pagina correttamente
		document.title = process.env.REACT_APP_APP_NAME_ABR + " - About me";
	}, []);

	return (
		<React.Fragment>
			<Header />
			<main className="about-me">
				<div className="about-me__background font-title">
					<DynamicText text={"About me"} isCapitalize={true} />
				</div>
				<div className="about-me__perfil">
					<div className="card_perfil">
						<div className="card_perfil__image">
							<img src={require(`../../images/aboutMe2.jpg`)} alt={"Foto di riccardo biffi"} loading="lazy" />
							<span>@Riccardo Biffi {new Date().getFullYear()}</span>
						</div>
						<div className="card_perfil__text">
							<p>
								<DynamicText
									text={"Sono un fotografo italiano amatoriale che ama immortalare natura, dettagli e paesaggi."}
									isCapitalize={true}
								/>
							</p>
							<p>
								<DynamicText
									text={
										"Ho iniziato la mia esperienza nel 2013 e da allora viaggio alla ricerca dello scatto perfetto."
									}
									isCapitalize={true}
								/>
							</p>
							<p>
								<DynamicText
									text={
										"Mi sono posto come obbiettivo quello di trasmettere la mia passione per la natura e per tutto ciò che ci circonda."
									}
									isCapitalize={true}
								/>
							</p>
							<p>
								<DynamicText text={"Sono originario della lombardia risiedo a Lodi."} isCapitalize={true} />
							</p>
							<div className="maps">
								<iframe
									src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d22446.76480696874!2d9.478191925875237!3d45.31154444857523!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47812c28b474095d%3A0x82c3b6f673d0fd73!2s26900%20Lodi%20LO!5e0!3m2!1sit!2sit!4v1721744361390!5m2!1sit!2sit"
									width="600"
									height="450"
									style={{ border: "0" }}
									loading="lazy"
									referrerPolicy="no-referrer-when-downgrade"
								></iframe>
							</div>
						</div>
					</div>
					<div className="about-me__counters">
						<Counter number={new Date().getFullYear() - 2013} text={"Anni di esperienza"} color={"light-blue-plus"} />
						<Counter number={jsonPhoto?.foto?.length} text={"Fotografie pubblicate"} color={"light-blue"} />
						<Counter number={6} text={"Fotocamere cambiate"} color={"light-blue-min"} />
					</div>
					<div className="about-me__social">
						<Link to={"https://www.instagram.com/riccardo.biffi.photo?igsh=N3Y5anJyOThrbWJ0"}>
							<div className="social-element">
								<img src={require(`../../images/insta.webp`)} alt={"Instagram"} loading="lazy" />
								<span>@riccardo.biffi.foto</span>
							</div>
						</Link>
						<Link to={"https://www.facebook.com/biffi.ricky"}>
							<div className="social-element">
								<img src={require(`../../images/facebook.png`)} alt={"Instagram"} loading="lazy" />
								<span>@Riccardo Biffi</span>
							</div>
						</Link>
					</div>
				</div>
			</main>
			<Footer />
		</React.Fragment>
	);
};

export default AboutMe;
